import { core } from "../../config/pluginInit";
import HighCharts from "highcharts";
import More from "highcharts/highcharts-more";
import HighChart from "../../components/core/charts/HighChart";
import { _ } from "vue-underscore";
import store from "@/store/index.js";
import TargetModal from "../../components/Global-Component/agent_target/index.vue";

More(HighCharts);
export default {
  name: "Dashboard1",
  components: {
    HighChart,
    TargetModal,
  },
  computed: {
    hasDashBoardAllAgentPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "dashboard-all-agent-data";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasDashBoardAgentPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "dashboard-agent-data";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },

  data() {
    return {
      user: JSON.parse(atob(localStorage.getItem("user"))),
      agentDashBoard: false,
      Monthly_GP_Target: true,
      Monthly_Target: "",
      monthData: "",
      agentList: [],
      TimeZoneOptions: ["EST", "UTC"],
      TimeZone: "EST",
      selectedAgent: "",
      showTargetModal: false,
      currentMonth: new Date().getMonth() + 1,
      selectedMonth: "",
      MonthList: [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
      ],
    };
  },

  methods: {
    openModal() {
      this.showTargetModal = true;
    },
    selectCurrentMonth() {
      this.selectedMonth = this.MonthList.find(
        (month) => month.value === this.currentMonth
      );
    },
    getTeamWiseAgentDetail() {
      store.state.isLoaderShow = true;
      const _vm = this;
      _vm.axios
        .get("/team-wise-user", _vm.agentList)
        .then(function (response) {
          _vm.agentList = response.data.data;
          if (!_vm.selectedAgent && _vm.agentList.length) {
            _vm.selectedAgent = _vm.agentList[0].id;
          }
          store.state.isLoaderShow = false;
        })
        .catch(function () { });
    },
    getAgentDashBoardData() {
      const _vm = this;
      store.state.isLoaderShow = true;
      const userId = _vm.selectedAgent || _vm.user.id;
      const data = {
        user_id: userId,
        month_number: _vm.selectedMonth.value,
        time_zone: _vm.TimeZone,
      };
      _vm.axios
        .post("/agent-dashboard", data)
        .then(function (response) {
          _vm.monthData = response.data.data;
          store.state.isLoaderShow = false;
        })
        .catch(function () { });
    },

    checkUserAndLoadData() {
      this.selectCurrentMonth();
      if (this.user) {
        this.selectedAgent = this.user.id;
        this.getTeamWiseAgentDetail();
        this.getAgentDashBoardData();
      }
    },
  },
  mounted() {
    this.checkUserAndLoadData();
  },
};
